@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: block;
  --cds-font-sans: 'Inter';

  @apply text-gray-900;
}

.root {
  width: 100%;
  height: 100%;
  display: block;
}

:focus-visible,
:focus {
  outline: 0;
}

[type='text']:focus {
  --tw-ring-color: 'transparent';
}

@layer utilities {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.no-scrollbar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
